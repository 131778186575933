import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { kdaColorStyle } from "@/app/util.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import ItemImage from "@/game-lol/components/ItemImg.jsx";
import RuneImg from "@/game-lol/components/RuneImg.jsx";
import TreeImg from "@/game-lol/components/TreeImg.jsx";
import {
  ROLE_SYMBOL_TO_STR,
  SERVICES_TO_REGIONS,
} from "@/game-lol/constants/constants.mjs";
import REGION_COLORS from "@/game-lol/constants/pro-region-colors.mjs";
import getHextechRoleIcon from "@/game-lol/utils/lol-icons.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getStaticData, mapRoleToSymbol } from "@/game-lol/utils/util.mjs";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const CONFIG = {
  title: ["common:navigation.proBuilds", "Pro Builds"],
  description: [
    "home:guides.lol.proBuilds.description",
    "Stay ahead of the LoL meta with Blitz.gg. Explore the historical data of pro player builds and emulate their strategies to win!",
  ],
  buttonText: ["common:navigation.viewProBuilds", "View Pro Builds"],
  buttonLink: "/lol/probuilds/history",
};

export default function HomeProbuildsTile() {
  const { t } = useTranslation();
  const {
    lol: { proBuildMatchlist },
  } = useSnapshot(readState);

  const perks = getStaticData("runes");

  const isLoading = !proBuildMatchlist[""];

  const data = useMemo(() => {
    if (!proBuildMatchlist[""]) return null;
    return proBuildMatchlist[""]
      .filter((match) => match.player?.fame >= 3)
      .sort((a, b) => {
        const aFame = a.player?.fame || 1;
        const bFame = b.player?.fame || 1;
        const aDate = new Date(a.timestamp).getTime();
        const bDate = new Date(b.timestamp).getTime();
        return bFame - aFame || bDate - aDate;
      })
      .slice(0, 3)
      .map((matchData) => {
        const { role, items, player } = matchData;
        const team = player.team;
        const teamImg = `${team.pictureUrl}`.startsWith("http")
          ? team.pictureUrl
          : Static.getProTeamImage(team.pictureUrl);
        const playerImg = player.profileImageUrl || player.portraitImageUrl;
        const region = SERVICES_TO_REGIONS[player.region];
        const regionColor = REGION_COLORS[player.region];
        const roleString = ROLE_SYMBOL_TO_STR[mapRoleToSymbol(role)].key;
        const RoleIcon = getHextechRoleIcon(roleString);

        const keystoneID = Number.parseInt(matchData.runes?.[0]?.id || 0);
        const keystone =
          perks && keystoneID && matchData.runePrimaryTree
            ? perks
                .find(
                  (tree) =>
                    tree.id === Number.parseInt(matchData.runePrimaryTree),
                )
                ?.slots?.[0]?.runes?.find(
                  (rune) => rune.id === Number.parseInt(keystoneID),
                )
            : null;

        const itemslist = matchData.boots[0]
          ? [
              ...items.map((e) => Number.parseInt(e.id)),
              Number.parseInt(matchData.boots[0].id),
            ]
          : items.map((e) => Number.parseInt(e.id));

        const kda =
          (matchData.kills + matchData.assists) / (matchData.deaths || 1);

        return {
          ...matchData,
          teamImg,
          team,
          playerImg,
          region,
          regionColor,
          RoleIcon,
          keystone,
          keystoneID,
          itemslist,
          kda,
        };
      });
  }, [proBuildMatchlist, perks]);

  if (isLoading) {
    return (
      <GameGuideTileLg
        title={CONFIG.title}
        description={CONFIG.description}
        buttonText={CONFIG.buttonText}
        href={CONFIG.buttonLink}
      >
        <Matchlist>
          <MatchContainer>
            <div
              className="match-summary loading-row"
              style={{ "--delay": "0.00s" }}
            />
          </MatchContainer>
          <MatchContainer>
            <div
              className="match-summary loading-row"
              style={{ "--delay": "0.05s" }}
            />
          </MatchContainer>
          <MatchContainer>
            <div
              className="match-summary loading-row"
              style={{ "--delay": "0.10s" }}
            />
          </MatchContainer>
        </Matchlist>
      </GameGuideTileLg>
    );
  }

  return (
    <GameGuideTileLg
      title={CONFIG.title}
      description={CONFIG.description}
      buttonText={CONFIG.buttonText}
      href={CONFIG.buttonLink}
    >
      <Matchlist>
        {data.map((match) => {
          const { RoleIcon } = match;
          return (
            <MatchContainer key={match.id}>
              <a
                className="match-summary"
                data-defeat={!match.win}
                href={`/lol/probuilds/history?matchId=${match.id}`}
              >
                <div className="player-info flex between align-center w-full gap-sp-2">
                  <div className="flex flex-start align-center gap-1">
                    <div className="player-image">
                      <img
                        height={32}
                        width={32}
                        src={match.playerImg}
                        alt={match.player.name}
                        loading="lazy"
                      />
                    </div>
                    <p className="type-caption--bold name">
                      {match.player.name}
                    </p>
                  </div>
                  <div className="flex flex-end align-center gap-2">
                    <div className="player-region">
                      <p
                        className="type-caption--bold"
                        style={{ color: match.regionColor }}
                      >
                        {match.region}
                      </p>
                    </div>
                    <div className="gap-dot" />
                    <div className="timestats">
                      <p className="type-caption--bold ago">
                        <TimeAgo date={match.timestamp} />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex between align-center w-full">
                  <div className="matchups flex align-center gap-sp-1">
                    <div className="relative">
                      <ChampionImg
                        disabled
                        size={32}
                        championId={match.champion}
                      />
                      {RoleIcon && (
                        <div className="player-role">
                          <RoleIcon />
                        </div>
                      )}
                    </div>
                    <span className="type-caption secondary">
                      {t("lol:vs", "vs")}
                    </span>
                    <ChampionImg
                      disabled
                      size={32}
                      championId={match.opponentChampion}
                    />
                  </div>
                  <div className="kdastats">
                    <p
                      className="type-caption--bold"
                      style={{ color: kdaColorStyle(match.kda) }}
                    >
                      {t("lol:matchTile.kda", "{{kda}} KDA", {
                        kda: match.kda.toLocaleString(getLocale(), {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }),
                      })}
                    </p>
                    <p className="type-caption secondary">
                      {t(
                        "lol:displayKDA",
                        "{{kills}} / {{deaths}} / {{assists}}",
                        {
                          kills: match.kills.toLocaleString(getLocale()),
                          deaths: match.deaths.toLocaleString(getLocale()),
                          assists: match.assists.toLocaleString(getLocale()),
                        },
                      )}
                    </p>
                  </div>
                  <div className="flex gap-4 align-center">
                    {perks && (
                      <div className="player-runes">
                        {match.runes[0] && match.runePrimaryTree && (
                          <>
                            <RuneImg
                              size={2.25}
                              isActive
                              currRune={match.keystone}
                            />
                            <div className="secondary">
                              <TreeImg
                                isActive
                                noBorder
                                size={1}
                                tree={perks.find(
                                  (tree) =>
                                    tree.id ===
                                    Number.parseInt(match.runeSecondaryTree),
                                )}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    <div className="flex gap-sp-1 player-items">
                      {[...Array(6)].map((_, i) => (
                        <ItemImage
                          key={i}
                          borderRadius={5}
                          size={1.75}
                          itemId={match.itemslist[i]}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </a>
            </MatchContainer>
          );
        })}
      </Matchlist>
    </GameGuideTileLg>
  );
}

const Matchlist = styled("ol")`
  --match-bg: var(--shade8);
  display: flex;
  flex-direction: column;
  list-style: none;

  li:not(:first-child) {
    box-shadow: inset 0px 1px var(--card-inset-border);
  }
`;

const MatchContainer = styled("li")`
  container-type: inline-size;
  container-name: home-probuilds;

  .match-summary {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--sp-2_5);
    height: 7rem;
    padding-inline: var(--sp-6);
    text-align: center;
    cursor: pointer;
    transition: background var(--transition);

    @container home-probuilds (inline-size <= 400px) {
      padding-inline: var(--sp-4);
    }

    &:hover {
      background: var(--shade3-15);
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: var(--sp-1);
      background: transparent;
    }

    &[data-defeat="true"]::before {
      background: var(--primary);
    }
    &[data-defeat="false"]::before {
      background: var(--turq);
    }

    > *:first-child {
      text-align: left;
    }
    > *:last-child {
      text-align: right;
    }
  }

  .player-image {
    position: relative;
    width: var(--sp-7);
    height: var(--sp-7);
    background: var(--shade10);
    border-radius: 50%;
    overflow: hidden;

    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 120%;
      transform: translate(-50%, -50%);
    }
  }
  .name,
  .ago {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .secondary {
    color: var(--shade1);
  }
  .player-role {
    position: absolute;
    bottom: -4px;
    right: -4px;
    padding: 2px;
    background: var(--card-surface);
    border-radius: var(--br);

    svg {
      width: var(--sp-3);
      height: var(--sp-3);
    }
  }
  .kdastats {
    @container home-probuilds (max-width: 480px) {
      display: none;
    }
    > p {
      justify-content: center;
      display: flex;
    }
  }
  .player-runes {
    position: relative;

    .secondary {
      position: absolute;
      bottom: 0;
      right: -0.25rem;
      background: var(--match-bg);
      padding: 0 0.125rem;
      border-radius: 50%;
    }

    @container home-probuilds (inline-size <= 400px) {
      display: none;
    }
  }
  .player-items {
    flex-wrap: wrap;
  }
  .gap-dot {
    height: var(--sp-1);
    width: var(--sp-1);
    background: var(--shade3);
    border-radius: 50%;
  }
`;
